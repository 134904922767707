<template>
    <div class="card card-danger">
        <div class="card-header">
            <h3 class="card-title">{{ $t('general.acceso') }}</h3>
            <div class="card-tools">
                <button type="button" @click="guardar()" class="btn btn-block btn-default btn-xs">{{ $t('general.guardar') }}</button>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label >{{ $t('general.usuario') }}</label>
                        <input type="text" class="form-control nombre_completo dato" id="nombre_completo3" v-model="login">
                    </div>
                    
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label >{{ $t('general.clave') }}</label>
                        <input type="password" class="form-control nombre_completo dato" v-model="clave" id="nombre_completo123" >
                    </div>   
                </div>
            </div>
            <!-- Se omite el acceso de email/clave ya que no se usa ni están contemplado en el acceso al webmail -->
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['operario', 'id'],
    data() {
        return {
            login: '',
            clave: '',
        }
    },
    methods: {
        cargardatos() {
            this.$store.dispatch('cambiarCargando', true);
            this.login = this.$props.operario.nombre;
            this.clave = this.$props.operario.contraseña;
            this.$store.dispatch('cambiarCargando', false);
            this.acceso = this.$t('general.acceso');
        },
        async guardar() {
            const api = new PwgsApi;
            this.$store.dispatch('cambiarGuardando', true);
           
                /* put  modulos/pwgsapi/index.php/usuarios/:id */
                const subidadatos = { nombre: this.login, contraseña: this.clave };
                try {
                    await api.put('usuarios/' + this.$props.operario.idusuario, subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                }
                catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: error, life: 5000 });
                }
                //this.cargardatos();
            
            
            this.$store.dispatch('cambiarGuardando', false);
        },
    },
    watch: {
        operario() {
            this.cargardatos();
        }
    },
}
</script>