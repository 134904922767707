<template>
    <div class="row">
        <div class="col-md-6">
            <operario-datos-datos :id="id" :operario="operario" />
        </div>
        <div class="col-md-4" v-if="(id > 0)">
            <operario-datos-app :id="id" :operario="operario"/>
            <operario-datos-acceso :id="id" :operario="operario" />
            <operario-datos-configuracion :id="id" :operario="operario" />
        </div>
    </div>
</template>
<script>
import OperarioDatosDatos from './OperarioDatosDatos.vue'
import OperarioDatosApp from './OperarioDatosApp.vue'
import OperarioDatosAcceso from './OperarioDatosAcceso.vue'
import OperarioDatosConfiguracion from './OperarioDatosConfiguracion.vue'
export default {
    props: ['id','operario'],

    components: {
        'operario-datos-datos': OperarioDatosDatos,
        'operario-datos-app': OperarioDatosApp,
        'operario-datos-acceso': OperarioDatosAcceso,
        'operario-datos-configuracion': OperarioDatosConfiguracion,
    },
    data() {
        return {}
    }
}
</script>
